<template>
  <v-data-table
    :headers="headers"
    :items="items"
    :items-per-page="15"
    class="elevation-0"
    :loading="loading"
    :loading-text="$t('loading_text')"
    :sort-by.sync="sortBy"
    :sort-desc.sync="sortDesc"
    :itemClass="itemClass"
    hide-default-header
    @click:row="$emit('click:row', $event)"
  >
    <!-- dynamic slots -->
    <template
      v-for="slot in Object.keys($scopedSlots)"
      v-slot:[slot]="slotScope"
    >
      <slot :name="slot" v-bind="slotScope" />
    </template>

    <!-- custom headers -->
    <template v-slot:header="{ props }">
      <thead class="v-data-table-header">
        <tr>
          <th
            v-for="header in props.headers"
            :key="header.value"
            role="columnheader"
            scope="col"
            aria-sort="none"
            class="text-start sortable"
          >
            <!-- information icon   -->
            <v-tooltip
              top
              max-width="250px"
              color="#007C82"
              v-if="header.description"
            >
              <template v-slot:activator="{ on, attrs }">
                <v-icon v-bind="attrs" v-on="on" color="#666666" class="mr-1">
                  mdi-information-outline
                </v-icon>
              </template>
              <span>{{ header.description }}</span>
            </v-tooltip>

            <span>{{ header.text }}</span>

            <img
              src="@/assets/imgs/table-sorting.svg"
              class="sorting-icon"
              v-if="header.sortable"
              @click="sort(header.value)"
            />
          </th>
        </tr>
      </thead>
    </template>
  </v-data-table>
</template>

<script>
export default {
  data() {
    return {
      sortBy: null,
      sortDesc: false,
    };
  },

  props: {
    headers: {
      type: Array,
      default: () => [],
    },
    items: {
      type: Array,
      default: () => [],
    },
    loading: {
      type: Boolean,
      default: false,
    },
    itemClass: {
      type: [String, Function],
      default: "",
    },
  },

  methods: {
    sort(value) {
      if (this.sortBy == value) {
        return (this.sortDesc = !this.sortDesc);
      }
      this.sortBy = value;
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/components/table.scss">
</style>