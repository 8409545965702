<template>
  <v-container class="members-index pb-0">
    <Breadcrumbs />

    <v-row>
      <v-col class="d-flex pb-0">
        <span class="icomoon icon-members d-flex members-icon"></span>
        <h1 class="d-flex ml-2 page-title text-capitalize">
          {{ $t("members") }}
        </h1>
      </v-col>
    </v-row>

    <!-- <v-row>
      <v-col cols="12" class="justify-space-between d-flex pt-6">
        <v-chip
          v-if="appliedFilters"
          class="applied-filters-chip mt-2"
          close
          close-icon="icomoon icon-close"
          @click:close="appliedFilters = null"
        >
          5 {{ $t("filters_applied") }}
        </v-chip>

        <v-btn
          width="192"
          height="46"
          class="filter-btn ml-auto"
          outlined
          color="#333333"
          @click.stop="showFiltersDialog = true"
        >
          {{ $t("filter_results") }}
        </v-btn>
      </v-col>
    </v-row> -->

    <Table
      :headers="headers"
      :items="membersList.members"
      :loading="loading"
      :class="`${isAdheraTheme ? 'mt-6' : 'mt-13'}`"
      @click:row="routeToProfile"
      :item-class="highlightedRow"
    >
      <template v-slot:item.email="{ item }">
        <span :style="membersList.styles['email']">{{ item.email }}</span>
      </template>

      <template v-slot:item.gender="{ item }">
        <span class="text-capitalize" v-if="item.gender == 1">
          {{ $t("male") }}
        </span>

        <span class="text-capitalize" v-if="item.gender == 2">
          {{ $t("female") }}
        </span>
      </template>

      <template v-slot:item.new_data="{ item }">
        <span class="text-capitalize" v-if="item.new_data == 0">
          {{ $t("no") }}
        </span>
        <v-chip
          :color="
            $store.getters['auth/user']
              ? $store.getters['auth/user'].theme_config.primary_color
              : ''
          "
          class="font-weight-bold numeric-chip"
          v-else
          >{{ item.new_data }}</v-chip
        >
      </template>

      <template v-slot:item.relapse.value="{ item }">
        <span class="badge-container">
          <v-badge
            dot
            color="#6BC0A5"
            class="d-inline-block"
            v-if="item.relapse.is_highlighted"
          ></v-badge>

          {{ item.relapse.relapsedWhenFormatted }}</span
        >
      </template>
    </Table>

    <MembersListFilterDialog :show="showFiltersDialog" />
  </v-container>
</template>

<script>
import Breadcrumbs from "@/components/Breadcrumbs.vue";
import Table from "@/components/Table.vue";

export default {
  data() {
    return {
      appliedFilters: null,
      showFiltersDialog: false,
      headers: [],
    };
  },

  components: {
    Breadcrumbs,
    Table,
    MembersListFilterDialog: () =>
      import("@/components/dialogs/MembersListFilterDialog.vue"),
  },

  computed: {
    isAdheraTheme: function () {
      // TO DO replace the user id with the theme name linked to that user
      return this.$store.getters["auth/user"]?.theme_config.theme == "adhera"
        ? true
        : false;
    },
    membersList: function () {
      return this.$store.getters["members/membersList"] ?? [];
    },
    loading: function () {
      return this.$store.getters["members/loading"];
    },
    membersIconPath: function () {
      return this.$store.getters["auth/user"]?.theme_config
        .avatar_main_colors == "#3452FF"
        ? require("@/assets/imgs/lifechamps-members.svg")
        : require("@/assets/imgs/members.svg");
    },
  },

  created() {
    this.fetchMembers();
  },

  watch: {
    membersList: function (newVal, oldVal) {
      this.headers = [];
      newVal.headers.forEach((header) => {
        this.headers.push({
          text: header.display_name,
          description: header.description,
          value: header.has_highlight
            ? `${header.header_key}.value`
            : header.header_key,
          sortable: true,
        });
      });
    },
  },

  methods: {
    highlightedRow(item) {
      if (item.new_data && item.new_data > 0) {
        return "highlighted";
      }
    },
    async fetchMembers() {
      await this.$store.dispatch("members/fetchMembers");
    },
    routeToProfile(e) {
      this.$router.replace({
        name: "members-profile",
        params: {
          id: e.id,
        },
      });
    },
  },
};
</script>

<style lang="scss" src="@/assets/sass/views/dashboard/members/index.scss">
</style>